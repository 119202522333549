import { useEffect, useState } from "react"
import imgLogoKonkoin2 from "../asset/logo-konkoin2.svg"

import axios from "axios"
import { baseurl } from "../config/http"

export default function Roadmap({ width }) {
  const [dataRoadmap, setDataRoadmap] = useState([])
  const [dataImage, setDataImage] = useState([])
  useEffect(() => {
    axios
      .get(baseurl + "/roadmap")
      .then(({ data }) => {
        setDataRoadmap(data[0])
      })
      .catch((err) => console.log(err))
  }, [])
  useEffect(() => {
    axios
      .get(baseurl + "/image")
      .then(({ data }) => {
        let temp = data.filter((e) => e.category === "roadmap")
        setDataImage(temp)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {dataRoadmap && dataImage? (
        <div
          className="container font-inter text-white"
          style={{ marginTop: 200 }}
        >
          {width <= 540 ? (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={imgLogoKonkoin2}
                  className="img-fluid float-end"
                  alt=""
                  style={{ height: 55 }}
                />
              </div>

              <div className=" text-center p-0 mt-4">
                <div
                  className=""
                  style={{ fontSize: width <= 540 ? 35 : 55, fontWeight: 400 }}
                  dangerouslySetInnerHTML={{ __html: dataRoadmap.header }}
                />
                <div className="mt-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataRoadmap.description,
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="row p-0 m-0">
              <div className="col-7 p-0 m-0">
                <div
                  className=""
                  style={{ fontSize: width <= 540 ? 35 : 55, fontWeight: 400 }}
                  dangerouslySetInnerHTML={{ __html: dataRoadmap.header }}
                />

                <div className={width <= 540 ? "mt-4 font-14" : "mt-4"}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataRoadmap.description,
                    }}
                  />
                </div>
              </div>
              <div className="col-5 d-flex justify-content-end align-items-center">
                <img
                  src={imgLogoKonkoin2}
                  className="img-fluid float-end"
                  alt=""
                  style={{ height: 80 }}
                />
              </div>
            </div>
          )}

          <div>
            <div className=" row mt-5">
              {dataImage.map((e, i) => {
                return (
                  <div
                  key={i}
                    className={
                      width <= 540
                        ? i === 0
                          ? "col-sm-6"
                          : "col-sm-6 mt-3"
                        : "col-sm-6"
                    }
                  >
                    <img
                      src={e.filename}
                      className={
                        i === 2 || i === 3
                          ? width > 540
                            ? "img-fluid roadmap mt-4"
                            : "img-fluid roadmap"
                          : "img-fluid roadmap"
                      }
                      style={{ borderRadius: 25 }}
                      alt=""
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
