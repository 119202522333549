import axios from "axios"
import { useEffect, useState } from "react"
import { baseurl } from "../config/http"

export default function News({ width }) {
  const [dataNews, setDataNews] = useState()
  useEffect(() => {
    axios
      .get(baseurl + "/news")
      .then(({ data }) => {
        setDataNews(data)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {dataNews ? (
        <div>
          <div
            className="container"
            style={{
              fontSize: width <= 540 ? 35 : width <= 760 ? 40 : 55,
              marginTop: width <= 540 ?   -100 : 0,
              fontWeight: 400,
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-white">Latest News</div>
              <div
                className="text-koni-danger"
                style={{
                  textDecoration: "underline",
                  fontSize: width <= 5400 ? 16 : 20,
                }}
              >
                See All
              </div>
            </div>
          </div>
          <div
            className="container"
            style={{ marginTop: width <= 540 ? 10 : 50 }}
          >
            <div
              className="scrollbar-none overflow-scroll"
              style={{ overflowY: "hidden" }}
            >
              <div style={{ width: "2500px" }}>
                {dataNews.map((e, i) => {
                  return (
                    <div
                      className="card border-0 me-3"
                      key={i}
                      style={{
                        width: 400,
                        display: "inline-block",
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        className="bg-danger"
                        style={{ height: 200, borderRadius: "25px 25px 0 0" }}
                      >
                        <img
                          src={e.image}
                          className="img-fluid h-100 w-100"
                          style={{
                            objectFit: "cover",
                            borderRadius: "25px 25px 0 0 ",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        className="bg-koni-secondary text-white"
                        style={{ height: 180, borderRadius: "0 0 25px 25px " }}
                      >
                        <div className="p-4">
                          <div className="d-flex justify-content-between align-items-center ">
                            <div className="fw-bold font-20">{e.headline}</div>
                            <div className="">{new Date(e.updatedAt).toLocaleDateString()}</div>
                          </div>
                          <div className="d-flex justify-content-between align-items-end mt-3">
                            <div className="font-14">
                             {e. detail}
                            </div>
                            <a
                              className=""
                              style={{ textDecoration: "underline", color : "white" }}
                              href={e.link}
                              target="__blank"
                            >
                              read
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
