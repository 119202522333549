// Update

import imgAppstore from "../asset/appstore.svg"
import imgGooglePlay from "../asset/google-play.svg"

import { useEffect, useState } from "react"
import axios from "axios"
import { baseurl } from "../config/http"

export default function Bento({ width }) {
  const [dataImage, setDataImage] = useState()
  const [dataImage2, setDataImage2] = useState()
  useEffect(() => {
    axios
      .get(baseurl + "/image")
      .then(({ data }) => {
        let temp = data.filter((e) => e.category === "bento")
        setDataImage(temp)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {dataImage ? (
        <div className="container" style={{ marginTop: width <= 540 ? 200 : 300 }}>
     
          <div className="row p-0 m-0 mt-3">
            <div
              className="col"
              style={{
                backgroundImage: `url(${dataImage[0].filename})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: 25,
              }}
            >
              <div className="row">
                <div className="col-6 d-flex justify-content-center">
                  <img
                    src={dataImage[1].filename}
                    className="img-fluid"
                    style={{
                      width: width <= 540 ? "100%" : "75%",
                      marginTop: width <= 540 ? 0 : 50,
                    }}
                    alt=""
                  />
                </div>
                <div className="col-6">
                  <div className="h-75 d-flex justify-content-start align-items-center">
                    <img
                      src={dataImage[2].filename}
                      className={width <= 540 ? "w-100" : "w-100"}
                      style={
                        width <= 540
                          ? { marginTop: 10, marginLeft: -35 }
                          : { marginTop: "10%", marginLeft: "-15%" }
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className="h-25"
                    style={
                      width <= 540
                        ? { marginLeft: "-20%", marginTop: "-5%" }
                        : {}
                    }
                  >
                    <img
                      src={imgAppstore}
                      className="me-3"
                      style={{ width: width <= 540 ? "45%" : "38%" }}
                      alt=""
                    />
                    <img
                      src={imgGooglePlay}
                      style={{ width: width <= 540 ? "45%" : "38%" }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row p-0 m-0  mt-3">
            <div
              className={
                width <= 540 ? "col-6 p-0 pe-2" : "col-sm-4 col-12 ps-0"
              }
            >
              <img
                src={dataImage[3].filename}
                alt=""
                className="img-fluid w-100 h-100"
                style={{ borderRadius: 25, objectFit: "cover" }}
              />
            </div>
            <div
              className={
                width <= 540 ? "col-6 p-0 ps-2" : "col-sm-4 col-12 p-0"
              }
            >
              <img
                src={dataImage[4].filename}
                alt=""
                className="img-fluid w-100 h-100"
                style={{ borderRadius: 25, objectFit: "cover" }}
              />
            </div>
            <div
              className={
                width <= 540
                  ? "col-sm-4 col-12 p-0 mt-3"
                  : "col-sm-4 col-12 pe-0"
              }
            >
              <img
                src={dataImage[5].filename}
                alt=""
                className="img-fluid w-100 h-100"
                style={
                  width <= 540
                    ? {
                        borderRadius: 25,
                        objectFit: "cover",
                        maxHeight: 189.59,
                        width: "100%",
                      }
                    : { borderRadius: 25, objectFit: "cover" }
                }
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
