import { useEffect, useState } from "react"
import axios from "axios"
import { baseurl } from "../config/http"

export default function Takeonomic({ width }) {
  const [dataBenefit, setDataBenefit] = useState()

  useEffect(() => {
    axios
      .get(baseurl + "/benefit")
      .then(({ data }) => {
        setDataBenefit(data[0])
      })
      .catch((err) => console.log(err))
  }, [])

  const [dataImage, setDataImage] = useState()
  useEffect(() => {
    axios
      .get(baseurl + "/image")
      .then(({ data }) => {
        let temp = data.filter((e) => e.category === "benefit")
        setDataImage(temp)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {dataImage && dataBenefit ? (
        <div
          className="container font-inter text-white"
          style={{ marginTop: width <= 540 ? 200 : 300 }}
        >
          {width <= 540 ? (
            <div
              className=" text-center px-5"
              style={{ fontWeight: 500, fontSize: 35, lineHeight: 1.2 }}
              dangerouslySetInnerHTML={{ __html: dataBenefit.header }}
            />
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-sm-6" style={{ height: "100%" }}>
              <img
                src={dataImage[0].filename}
                className="img-fluid p-5"
                alt=""
                style={{ marginTop: width <= 540 ? 0 : 100 }}
              />
            </div>

            <div className="col-sm-6">
              {width <= 540 ? (
                ""
              ) : (
                <div
                  className=""
                  style={{ fontSize: 55, fontWeight: 400, lineHeight: 1.2 }}
                  dangerouslySetInnerHTML={{ __html: dataBenefit.header }}
                />
              )}

              <div
                className={
                  width <= 540 ? " h-100 font-inter" : "h-100 font-inter mt-5"
                }
              >
                <div>
                  <img
                    src={dataImage[1].filename}
                    className="img-fluid mt-3"
                    style={{ borderRadius: 25 }}
                    alt=""
                  />
                  <img
                    src={dataImage[2].filename}
                    className="img-fluid mt-3"
                    style={{ borderRadius: 25 }}
                    alt=""
                  />
                  <img
                    src={dataImage[3].filename}
                    className="img-fluid mt-3"
                    style={{ borderRadius: 25 }}
                    alt=""
                  />
                  <img
                    src={dataImage[4].filename}
                    className="img-fluid mt-3"
                    style={{ borderRadius: 25 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
