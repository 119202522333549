import { useEffect, useState } from "react"
import imgLogoKonkoin2 from "../asset/logo-konkoin2.svg"
import axios from "axios"
import { baseurl } from "../config/http"

export default function Konkoin({ width }) {
  const [dataKonkoin, setDataKonkoion] = useState()
  const [dataPoint, setDataPoint] = useState({
    1: {
      title: "",
      purpose: "",
    },
    2: {
      title: "",
      purpose: "",
    },
    3: {
      title: "",
      purpose: "",
    },
  })
  const dummy = [[], [], [], []]
  useEffect(() => {
    axios
      .get(baseurl + "/konkoin")
      .then(({ data }) => {
        let header = data[0].header
        let description = data[0].description
        let point = JSON.parse(data[0].point)
        setDataKonkoion({ header, description })
        setDataPoint({
          0: {
            title: point[0].title,
            purpose: point[0].purpose,
          },
          1: {
            title: point[1].title,
            purpose: point[1].purpose,
          },
          2: {
            title: point[2].title,
            purpose: point[2].purpose,
          },
        })
      })
      .catch((err) => console.log(err))
  }, [])

  const [dataImage, setDataImage] = useState()
  useEffect(() => {
    axios
      .get(baseurl + "/image")
      .then(({ data }) => {
        let temp = data.filter((e) => e.category === "konkoin")
        setDataImage(temp)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {dataKonkoin && dataPoint && dataImage ? (
        <div
          className="container-fluid"
          style={{ backgroundImage: "", marginTop: width <= 540 ? 100 : 180 }}
        >
          <div className="container font-inter text-white">
            <div className="row p-0 m-0 mb-5">
              <div className="col-sm-7 col-12 p-0 m-0">
                <div
                  className=""
                  style={{ fontSize: width <= 540 ? 35 : 60, fontWeight: 400 }}
                >
                  {width <= 540 ? (
                    <>
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={imgLogoKonkoin2}
                          className="img-fluid"
                          alt=""
                          style={{ height: 55 }}
                        />
                      </div>
                      <div
                        className="text-center mt-4"
                        dangerouslySetInnerHTML={{ __html: dataKonkoin.header }}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        dangerouslySetInnerHTML={{ __html: dataKonkoin.header }}
                      />
                    </>
                  )}
                </div>
                <div
                  className={
                    width <= 540 ? "mt-4 font-14 text-center" : "mt-4 font-18"
                  }
                  dangerouslySetInnerHTML={{ __html: dataKonkoin.description }}
                />
              </div>
              {width <= 540 ? (
                ""
              ) : (
                <div className="col-5 d-flex justify-content-end align-items-center">
                  <img
                    src={imgLogoKonkoin2}
                    className="img-fluid float-end"
                    alt=""
                    style={{ height: 80 }}
                  />
                </div>
              )}
            </div>
            {Object.keys(dataPoint).map((e, i) => (
              <div key={i}>
                <div
                  className="bg-white opacity-25 "
                  style={{ height: 2 }}
                ></div>

                <div className="row">
                  <div className="col-sm-8">
                    <div
                      className={
                        width <= 540
                          ? "d-flex  justify-content-center align-items-start"
                          : "d-flex  py-2"
                      }
                    >
                    
                      <div
                        className="text-white"
                        style={{ fontSize: width <= 540 ? 30 : 80 }}
                      >
                        {dataPoint[e].title}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      width <= 540
                        ? "col-12 p-4 pt-0 text-center font-14 mb-3"
                        : "col-4 p-4 text-end font-18"
                    }
                    dangerouslySetInnerHTML={{ __html: dataPoint[e].purpose }}
                  />
                </div>
              </div>
            ))}

            <div className="bg-white opacity-25" style={{ height: 2 }}></div>
          </div>
          <div
            className="container"
            style={{ marginTop: width <= 540 ? 10 : 50, width: "100%" }}
          >
            {/* <div
              className="scrollbar-none overflow-scroll"
              style={{ overflowY: "scroll", width: "100%" }}
            >
              <div
                style={{ width: "100%", whiteSpace: "nowrap" }}
                className=" "
              >
                {dataImage.map((e, i) => {
                  return (
                    <div
                      className={
                        i === dataImage.length - 1
                          ? "card border-0"
                          : "card border-0 me-4"
                      }
                      key={i}
                      style={{
                        width: "49%",

                        display: "inline-block",
                        backgroundColor: "",
                        borderRadius: width <= 540 ? 15 : 25,
                      }}
                    >
                      <img
                        src={dataImage[i].filename}
                        className="img-fluid w-100 h-100"
                        style={{ objectFit: "cover", borderRadius: width <= 540 ? 15 : 25 }}
                        alt=""
                      />
                    </div>
                  )
                })}
              </div>
            </div> */}
          </div>

        </div>
      ) : (
        ""
      )}
    </>
  )
}
