import axios from "axios"
import { useEffect, useState } from "react"
import { baseurl } from "../config/http"

export default function Banner({ width }) {
  const [dataBanner, setDataBanner] = useState()
  useEffect(() => {
    axios
      .get(baseurl + "/banner")
      .then(({ data }) => {
        setDataBanner(data[0])
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {dataBanner ? (
        <div className="container mt-4 font-inter">
          <div className="row text-white pb-5">
            <div className="col-12">
              <div
                className={
                  width <= 540 ? " text-inter text-center" : " text-inter"
                }
                style={{
                  fontSize: width <= 540 ? 35 : width <= 760 ? 50 : 80,
                  marginTop: width <= 540 ? "80%" :  "50%",
                  fontWeight: 500,
                  lineHeight: 1.15,
                }}
              >
                <div>{dataBanner.header}</div>
              </div>
              <div
                className={
                  width <= 540
                    ? "mt-4 text-center font-14"
                    : "mt-4 pb-4 pe-5 font-30"
                }
              >
                {dataBanner.description}
              </div>
              <div className="mt-5 d-flex justify-content-center  w-100">
                {/* <div
                  className={
                    width <= 540
                      ? "btn ms-3 me-2 text-white w-100"
                      : "btn me-4 px-4 text-white"
                  }
                  style={{
                    backgroundColor: "#252525",
                    borderRadius: 25,
                    width: width < 540 ? "" : 160,
                    fontWeight: 500,
                    fontSize: 18,
                    height: 45,
                  }}
                >
                  {dataBanner.button1}
                </div> */}
                <div
                  className={
                    width <= 540
                      ? "btn me-3 text-white w-100"
                      : "btn me-4 px-4 text-white"
                  }
                  style={{
                    backgroundColor: "#d91f2d",
                    borderRadius: 25,
                    width: width < 540 ? "" : "30%",
                    fontWeight: 500,
                    fontSize: width <= 540 ? 18 : 24,
                    height: width <= 540 ?45 : 60,
                  }}
                >
                  <div className="d-flex justify-content-center align-items-center h-100">

                  {dataBanner.button2}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
