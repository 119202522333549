/* eslint-disable*/
import React from "react"
import { Route, Routes } from "react-router-dom"

import "./index.css"

import LandingPage from "./page/LandingPage"
import KYCVerivicationProcess from "./page/Success"
import TNC from "./page/TNC"
import FailedNotification from "./page/Failed"

function App() {
  return (
    <div className="App text-inter bg-koni-dark" style={{ overflow: "hidden" }}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/tnc" element={<TNC />} />
        <Route
          path="/notification/success"
          element={<KYCVerivicationProcess />}
        />
        <Route path="/notification/failed" element={<FailedNotification />} />
      </Routes>
    </div>
  )
}

export default App
