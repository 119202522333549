import Marquee from "react-fast-marquee"


import { useEffect, useState } from "react"
import axios from "axios"
import { baseurl } from "../config/http"
export default function MediaPartner({ width }) {
  const [dataImage, setDataImage] = useState()
  useEffect(() => {
    axios
      .get(baseurl + "/image")
      .then(({ data }) => {
        let temp = data.filter((e) => e.category === "media")
        setDataImage(temp)
      })
      .catch((err) => console.log(err))
  }, [])
  return (
    <>
      {dataImage ? (
        <div
          className="container"
          style={{ marginTop: width <= 540 ? 100 : 250 }}
        >
          <div className="font-30 text-center text-white fw-bold mb-5">
            Media Partner
          </div>

          <Marquee
            pauseOnHover={true}
            style={{ margin: "0 50px 0 50px", zIndex: 0 }}
          >
            { dataImage.map((e,i) => {
              return  <img
              key={i}
              src={ dataImage[i].filename}
              style={{ maxWidth: 250 }}
              className="img-fluid me-5"
              alt=""
            />
            })}
           
       
          </Marquee>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
